import { IUser } from './IUser';
import { ILevel } from './ILevel';

export interface ICompany {
    id: number;
    name: string;
    domain: string | null;
    loginLogo: string | null;
    loginBackground: string | null;
    templateLogo: string | null;
    colorPrimary: string;
    colorSecondary: string;
    colorSuccess: string;
    colorDanger: string;
    colorWarning: string;
    colorInfo: string;
    migrated: boolean;
    isActive: boolean;
    oldId: number | null;
    levelsCount: number;
    numbersCount: number;
    usersCount: number;
    nationalCallRate: number;
    internationalCallRate: number;
    mobileCallRate: number;
    tollFreeNationalCallRate: number;
    tollFreeMobileCallRate: number;
    maxUserDevice: number;
    userDevicesTotal?: number;
    createdBy: number;
    updatedBy: number | null;
    createdAt: string;
    updatedAt: string;
    levels: ILevel[];
    users: IUser[];
    createdByUser?: IUser;
    updatedByUser?: IUser | null;
    bucketBaseUrl?: string;
    adminEmail?: string;
}

export enum ThemeDefaultColors {
    PRIMARY = '36, 43, 89',
    SECONDARY = '85, 85, 85',
    SUCCESS = '40, 167, 69',
    DANGER = '220, 53, 69',
    WARNING = '255, 193, 7',
    INFO = '23, 162, 184',
}
